exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-cv-index-js": () => import("./../../../src/pages/cv/index.js" /* webpackChunkName: "component---src-pages-cv-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-publications-index-js": () => import("./../../../src/pages/publications/index.js" /* webpackChunkName: "component---src-pages-publications-index-js" */),
  "component---src-templates-year-js": () => import("./../../../src/templates/year.js" /* webpackChunkName: "component---src-templates-year-js" */)
}

